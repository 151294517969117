.nav-menu {
    position: fixed;
    top: 0; 
    right: 0;
    height: 100vh;
    width: 100vw;
    background: #101010;
    display: flex;
    justify-content: center;
    z-index: 100;

    @media (min-width: 601px) {
        display: none;
    }

    .nav-close {

        span {
            position: absolute;
            top: 8.5rem;
            right: 6.5rem;
            width: 30px;
            height: 1px;
            display: block;
            background: #f9f5ef;
            transform: rotate(135deg);

            &:last-of-type {
                transform: rotate(-135deg);
            }
        }
    }
    
    ul {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {

            margin: 5rem;
            list-style: none;

            a {
                color: #f9f5ef;
                font-size: 4rem;
                font-family: $ff2;

                @media (max-width: 600px) {
                    font-size: 8rem;
                }
            }
        }
    }
}