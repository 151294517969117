.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;

    .first {
        span {
            &:last-of-type {
                margin-right: 5rem;

                @media (max-width: 600px) {
                    margin-right: 3rem;
                }
            }
        }
    }

    span {
        font-size: 15rem;
        font-weight: 300;
        display: inline-block;
        text-transform: uppercase;

        @media (max-width: 800px) {
            font-size: 10rem;
        }
    }

    .line {
        position: absolute;
        top: 50%;
        left: 0;
        background: #f9f5ef;
        height: 2px;
        width: 100vw;
        mix-blend-mode: exclusion;
    }
}

.left-panel-background, .right-panel-background {
    height: 100vh;
    width: 50vw;
    position: absolute;
    z-index: 550;
    background: #000;
}

.left-panel-background {
    left: 0;
}

.right-panel-background {
    right: 0;
}