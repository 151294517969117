.header {
    height: min(100vh, 1500px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1, h4, h5 {
        text-transform: uppercase;
    }

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: .5px;
            opacity: .5;
            background: $secondary;
            bottom: -4rem;
            left: 0;
        }

        h5 {
            font-family: $ff2;
            font-weight: 400;
            font-size: 3rem;
            letter-spacing: 1.5px;

            @media (max-width: 900px) {
                font-size: 5rem;
            }
        }

        ul {
            display: flex;

            @media (max-width: 600px) {
                display: none;
            }

            li {
                list-style: none;
                margin-left: 13rem;

                a {
                    text-decoration: none;
                    font-size: 2.5rem;
                    
                    &:hover {
                        text-decoration: line-through;
                    }
                }

                @media (max-width: 900px) {
                    margin-left: 10rem;

                    a {
                        font-size: 3rem;
                    }
                }

            }
        }

        .nav-toggle {

            @media (min-width: 601px) {
                display: none;
            }

            padding: 10px;

            span {
                width: 30px;
                height: 1px;
                display: block;
                background: $secondary;

                &:last-of-type {
                    margin-top: 5px;
                }
            }
        }
    }

    .banner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: min(100rem, 70vh);
        text-align: center;

        @media (max-width: 1024px) {
            height: 70vh;
        }

        &__upper {

            line-height: 2;

            h1 {
                font-size: 25rem;
                margin-bottom: 2rem;
            }

            h4 {
                font-size: 8rem;
                font-family: $ff2;
                font-weight: 100;
                text-align: center;
            }  
    
            

        
        
            

            .IvanHill {
                font-size: 7rem;
                font-weight: 700;
                opacity: .8;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 34em;
                overflow: hidden;
                

                span {
                    margin:  2.5rem;
                }

            }

        }
    }
}
