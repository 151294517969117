.about {
    p {
        font-size: 2.8rem;
        line-height: 4.5rem;
        letter-spacing: 1px;

        @media (max-width: 600px) {
            font-size: 4rem;
            line-height: 1.3;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50rem;

        .me {
            height: 100vh;
            width: 100vw;
            scroll-margin-block: 1rem;
            object-fit: contain;
            opacity: .9;
        }

        .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 10;

            &__inner {
                margin-top: 15rem;
                width: 80rem;
                position: relative;

                .interest {
                    margin: 10rem 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: -2rem;
                    left: 0;
                    width: 98%;
                    height: .5px;
                    background: $secondary;
                }

            }
        }

    }

    .tech-stack {
        margin-top: 2rem;
        margin-bottom: 30rem;

        h3 {
            text-align: center;
        }

        ul {
            display: flex;
            justify-content: space-evenly;
            margin-top: 7rem;

            li {
                list-style: none;
                font-family: $ff2;
                font-size: 5rem;
                font-weight: 400;
                margin: 5rem;
            }

            @media (max-width: 450px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}
